import { Link } from "gatsby"
import React from "react"

import styled from "styled-components"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = { collapsed: true }
    this.handleMenuToggle = this.handleMenuToggle.bind(this)
  }

  handleMenuToggle() {
    this.setState({
      collapsed: !this.state.collapsed,
    })
  }

  render() {
    return (
      <StyledHeader className={this.state.collapsed ? "closed" : "open"}>
        <HeaderBar>
          <BurgerMenu
            onClick={this.handleMenuToggle}
            aria-expanded={!this.state.collapsed}
            aria-label="Hauptmenü öffnen / schließen"
          >
            <BurgerMenuBar className="top-bar" />
            <BurgerMenuBar className="middle-bar" />
            <BurgerMenuBar className="bottom-bar" />
          </BurgerMenu>
        </HeaderBar>
        <Navbar
          id="navbar"
          className={this.state.collapsed ? "main-menu collapsed" : "main-menu"}
        >
          <Link to="/">Home</Link>
          <Link to="/#game_download">Game Download</Link>
          <Link to="/#informations_and_downloads">
            Unterrichtsmaterialien &amp; Presse Downloads
          </Link>
          <Link to="/#the_story_behind">Die Geschichte dahinter</Link>
          <Link to="/#contact">Kontakt</Link>
          <Link to="/#sponsors">Sponsoren</Link>
        </Navbar>
      </StyledHeader>
    )
  }
}

export default Header

const StyledHeader = styled.header`
  position: absolute;
  top: 0;
  z-index: 100;
  height: auto;
  display: block;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    padding: 10px 15px;
  }

  &.closed {
    height: 60px;

    @media (min-width: 768px) {
      height: auto;
    }
  }
`

const HeaderBar = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  background-color: #71432d;
  align-items: center;
  justify-content: flex-end;

  @media (min-width: 768px) {
    display: none;
  }
`

const BurgerMenu = styled.button`
  background: none;
  border: none;
  padding: 0;
  display: inline-block;
  width: 24px;
  height: 50px;

  &:focus {
    outline: none;
  }

  &[aria-expanded="true"] {
    .top-bar {
      transform: rotate(-45deg) translate(-6px, 6px);
    }

    .middle-bar {
      opacity: 0;
    }

    .bottom-bar {
      transform: rotate(45deg) translate(-7px, -7px);
    }
  }
`

const BurgerMenuBar = styled.span`
  width: 24px;
  height: 3px;
  background-color: #e5dfdc;
  margin: 6px 0;
  transition: 0.4s;
  display: block;
`

const Navbar = styled.nav`
  padding: 5px 15px;
  overflow: hidden;
  transition: transform 0.3s ease-out;
  height: auto;
  transform: scaleY(1);
  transform-origin: top;
  background-color: #71432d;
  display: flex;
  flex-direction: column;

  &.collapsed {
    transform: scaleY(0);
  }

  a {
    font-family: "Norse", "Arial", "Helvatica", sans-serif;
    margin: 0 10px;
    padding: 5px 10px;
    color: #e5dfdc;
    text-decoration: none;
    font-size: 25px;
    font-weight: 700;
    display: inline-block;
  }

  @media (min-width: 768px) {
    background-color: transparent;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    &.collapsed {
      transform: scaleY(1);
    }
  }
`
